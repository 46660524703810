var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardSharableListStyled', [_c('UserWrapperStyled', [_c('AvatarBadge', {
    attrs: {
      "size": '30px',
      "fontSize": '14px',
      "userData": _vm.userMapped,
      "isOwner": false
    }
  }), _c('UserStyled', [_c('div', [_vm._v(_vm._s(_vm.user.username))]), _c('div', {
    staticClass: "sub-text"
  }, [_vm._v(_vm._s(_vm.user.email))])])], 1), !_vm.user.isOwner && !_vm.user.allUsersList ? _c('DeleteIcon', {
    staticClass: "delete",
    on: {
      "click": function click($event) {
        return _vm.deleteDashboardShares(_vm.user.shareId);
      }
    }
  }) : _vm._e(), _vm.user.isOwner ? _c('OwnerBadge') : _vm._e(), _vm.user.allUsersList ? _c('PlusIcon', {
    staticClass: "icon",
    on: {
      "click": function click($event) {
        return _vm.createDashboardShare(_vm.user.id);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }